import React from "react"
import { get, post, postJson } from "./events"

export function is_nothing(o: any): o is undefined | null {
	return o === undefined || o === null || (typeof (o) === "number" && isNaN(o)) || o === ''
}

export function one_to_percent(num: number, fixed?: number) {
	return `${(num * 100).toFixed(fixed || 2)}%`
}

export function get_percent(value: number, total: number): number {
	return Math.trunc((100 * value) / total)
}

export async function create_order(productId: TProductId, currency: TCurrency, turnOnAutoSub: boolean) {
	const data = await post<TCreateOrderResponse>("/api/payment/order", JSON.stringify({
		productId,
		currency,
		turnOnAutoSub
	}))

	if (data && !data.hasError) {
		window.open(data.url)
	} else if (data && data.hasError) {
		alert(data.errorMessage);
	}
}

export async function create_present_order(productId: TProductId, currency: TCurrency,
	orderType: "coupon" | "gift", giftPlayerId?: number, giftMessage?: string): Promise<TCreateOrderResponse> {

	let model;

	switch (orderType) {
		case "coupon":
			model = {
				productId,
				currency,
				type: 1,
			};
			break;
		case "gift":
			model = {
				productId,
				currency,
				type: 2,
				giftPlayerId,
				giftMessage
			};
			break;
	}

	const data = await post<TCreateOrderResponse>("/api/payment/order", JSON.stringify(model))

	if (data!.hasError)
		alert(data?.errorMessage);
	else
		window.open(data?.url)

	return data!;
}

export async function getOrderStatus(orderId: number, checkOrderStatus: boolean): Promise<TProductOrderStatus | undefined> {
	return await postJson<TProductOrderStatus>("/api/payment/order_status", {
		orderId
	})!
}

function wait(millisec: number) {
	return new Promise(resolve => {
		setTimeout(() => { resolve('') }, millisec);
	})
}

export function background_style(path: string) {
	return { backgroundImage: `url( '${path}' )` }
}

export function R_on_click_outside(ref: React.MutableRefObject<HTMLElement | null>, func: () => void) {
	React.useEffect(
		() => {
			const listener = (event: TouchEvent | MouseEvent) => {
				if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
					return
				}

				func()
			}

			document.addEventListener("mousedown", listener)
			document.addEventListener("touchstart", listener)

			return () => {
				document.removeEventListener("mousedown", listener)
				document.removeEventListener("touchstart", listener)
			}
		},
		[ref, func]
	)
}

export function R_on_change_location(func: () => void) {
	React.useEffect(
		() => {
			func();
		},
		[window.location.href]
	)
}

export function to_2_sym(s: string | number) {
	if (s.toString().length === 0) {
		return "00"
	} else if (s.toString().length === 1) {
		return `0${s}`
	} else {
		return s
	}
}

export function copy_array_with_condition<T>(a: T[], f: (e: T) => boolean) {
	const newArray: T[] = []

	for (const e of a) {
		if (f(e)) {
			newArray.push(e)
		}
	}

	return newArray
}

export function getClearHeroName(heroName: string, clearUnders?: boolean) {
	clearUnders = is_nothing(clearUnders) ? true : clearUnders;

	const retval = heroName
		.replace('npc_dota_hero_', '');

	return clearUnders ? retval.replaceAll('_', ' ') : retval;
}

export function getUserFrendlyHeroName(heroName: string): string {
	let retval = userFrendlyHeroNames[heroName];

	return is_nothing(retval)
		? upperFirstLetter(heroName
			.replace('npc_dota_hero_', '')
			.replaceAll('_', ' '))
		: retval;
}

const userFrendlyHeroNames: TKV<string, string> = {
	"npc_dota_hero_furion": "Nature's Prophet",
	"npc_dota_hero_nevermore": "Shadow Fiend",
	"npc_dota_hero_queenofpain": "Queen of Pain",
	"npc_dota_hero_skeleton_king": "Wraith King",
	"npc_dota_hero_zuus": "Zeus"
}

function upperFirstLetter(input: string): string {
	return input.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());;
}