import { FlowBlock, Loading, SizedImage, DotaPlusSubscribe } from "./samples"
import { getGameStage, localize } from "../components/localization";
import { getUserFrendlyHeroName, background_style, is_nothing } from "../components/utils";
import { R_get_stats, set_state, R_subscribe } from "../components/events";
import { ReactNode, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const ItemBuildContainer: React.FC<{
	heroName: string
}> = props => {
	const heroName = props.heroName;
	const authorized = R_subscribe("authorized");

	return authorized && authorized.authorized && authorized.dotaPlusExpireDate && authorized.dotaPlusExpireDate > Date.now()
		? <ItemBuildsWithSub heroName={heroName} />
		: <ItemBuildsWithoutSub />
}

const ItemBuildsWithoutSub: React.FC<{}> = props => {
	return <div>
		<div className="hidden xl:block">
			<ItemBuildsWithoutSubDesktop />
		</div>
		<div className="xl:hidden">
			<ItemBuildsWithoutSubMobile />
		</div>
	</div>
}

const ItemBuildsWithoutSubDesktop: React.FC<{}> = props => {
	return <div className="p-5 text-[#ddd] font-semibold text-lg text-shadow"
		style={{
			backgroundImage: `url( '../images/dota_plus/bg/itemsBuildsUnsub.png' )`,
			backgroundSize: "100%", backgroundRepeat: "no-repeat", height: "350px"
		}}
	>
		<div className="items-build-title-container mb-2">
			<div className={`font-bold text-white text-3xl`}>
				{localize("recommendedItems")}
			</div>
		</div>
		<div className="mb-12 text-2xl">
			{localize("item_builds_not_available")}
		</div>
		<div className="flex flex-col items-center">
			<DotaPlusSubscribe />
		</div>
	</div>
}

const ItemBuildsWithoutSubMobile: React.FC<{}> = props => {
	return <div className="p-5 text-[#ddd] font-semibold text-lg text-shadow"
	>
		<div className="items-build-title-container mb-2">
			<div className={`font-bold text-white text-3xl`}>
				{localize("recommendedItems")}
			</div>
		</div>
		<div className="mb-12 text-xl">
			{localize("item_builds_not_available")}
		</div>
		<div className="flex flex-col items-center">
			<DotaPlusSubscribe />
		</div>
	</div>
}

const ItemBuildsWithSub: React.FC<{
	heroName: string
}> = props => {
	const heroName = props.heroName;
	const talentName = R_subscribe("itemBuildsTalent");

	const builds = R_get_stats<THeroItemBuild>(`/api/hero_builds?heroName=${heroName}`);

	return builds
		? <div className="p-5 text-[#ddd] xl:w-[1250px] font-semibold text-lg text-shadow">
			<div className="items-build-title-container mb-5 flex flex-col lg:justify-between lg:flex-row">
				<div className={`font-bold text-white text-3xl lg:text-3xl`}>
					{localize("recommendedItems")}
				</div>
				<div className="flex pt-5 sm:pt-0 justify-center">
					<TalentBlockContainer builds={builds} talentName={talentName} />
				</div>
			</div>
			<ItemBuildTables talentName={talentName} heroBuilds={builds} />
			<div className={`mt-8 text-gray-400`}>
				{localize("dota_item_builds_description")}
			</div>
		</div>
		: <div className="w-[1228px]">
			<Loading />
		</div>
}

const TalentBlockContainer: React.FC<{
	builds: THeroItemBuild,
	talentName: string | null | undefined
}> = props => {

	const builds = props.builds;
	const talents = [] as TTalent[];
	const talentName = props.talentName;

	builds.talentNames.forEach(t => {
		const isActive = talentName === t;
		talents.push({ talentName: t, isActive: isActive } as TTalent)
	});

	if (is_nothing(talentName)) {
		talents[0].isActive = true;
	}

	return <div className="flex flex-row">
		<TalentBlock talent={talents[0]} />
		<TalentBlock talent={talents[1]} />
		<TalentBlock talent={talents[2]} />
		<TalentBlock talent={talents[3]} />
	</div>
}

const TalentBlock: React.FC<{
	talent: TTalent
}> = props => {

	const talent = props.talent;
	const [searchParams, setSearchParams] = useSearchParams()

	const activeState = talent.isActive
		? 'shadow-orange'
		: 'brightness-[20%] hover:brightness-[70%] shadow-custom-xs';

	return <div className={` rounded-xl ml-3`}>
		<button
			onClick={() => {
				set_state('itemBuildsTalent', talent.talentName);
			}}>
			<SizedImage
				className={`h-[65px] overflow-hidden mx-auto shadow-sm ${activeState}`}
				src={`/images/talents/${talent.talentName}.png`}
			/>
		</button>
	</div>
}

const ItemBuildTables: React.FC<{
	heroBuilds: THeroItemBuild,
	talentName: string | null | undefined
}> = props => {

	let talentName = props.talentName;
	const talentBuilds = props.heroBuilds.talentBuilds;

	if (!talentName) {
		talentName = props.heroBuilds.talentNames[0];
		set_state("itemBuildsTalent", props.heroBuilds.talentNames[0]);
	}

	let builds = talentBuilds[talentName];

	if (is_nothing(builds)) {
		set_state("itemBuildsTalent", props.heroBuilds.talentNames[0]);
	}

	return <div className="flex justify-center xl:justify-between">
		<div className="grid grid-cols-1 sm:grid-cols-4 space-y-5">
			<ItemBuildTable gameStage="start" itemBuild={builds?.startItems} />
			<ItemBuildTable gameStage="early" itemBuild={builds?.earlyItems} />
			<ItemBuildTable gameStage="mid" itemBuild={builds?.midItems} />
			<ItemBuildTable gameStage="late" itemBuild={builds?.lateItems} isLast={true} />
		</div>
	</div>
}

const ItemBuildTable: React.FC<{
	gameStage: TGameStage
	itemBuild?: TItemBuild
	isLast?: boolean
}> = props => {

	const imagePaths: string[] = [];
	const itemBuild = props.itemBuild;

	if (is_nothing(itemBuild)) {
		for (let index = 0; index < 6; index++) {
			imagePaths.push('/images/dota_items/empty.png');
		}
	}
	else {
		itemBuild.items.forEach(item => {
			const trimedItem = item.replace('item_', '')
			imagePaths.push(`/images/dota_items/${trimedItem}.png`);
		});
	}

	const shardBuffImg = itemBuild?.hasAghanimShard
		? "/images/dota_items/aghsstatus_shard_on.png"
		: "/images/dota_items/aghsstatus_shard_off.png"

	const scepterBuffImg = itemBuild?.hasAghanimScepter
		? "/images/dota_items/aghsstatus_scepter_on.png"
		: "/images/dota_items/aghsstatus_scepter_off.png"

	return <div className={`flex flex-col w-[290px] items-build-block-container ${props.isLast ? '' : 'mr-5'}`}>
		<p dangerouslySetInnerHTML={{ __html: getGameStage(props.gameStage) }}>
		</p>
		<div className="last items-build-table-container shadow-sm shadow-black"
			style={background_style("/images/dota_items/inventory_bg.png")}>
			<table>
				<tbody>
					<tr>
						<td><SizedImage className="items-build-img m-1 w-16" src={imagePaths[0]} /></td>
						<td><SizedImage className="items-build-img m-1 w-16" src={imagePaths[1]} /></td>
						<td><SizedImage className="items-build-img m-1 w-16" src={imagePaths[2]} /></td>
						<td><SizedImage className="m-1 w-12" src={scepterBuffImg} /></td>
					</tr>
					<tr>
						<td><SizedImage className="items-build-img m-1 w-16" src={imagePaths[3]} /></td>
						<td><SizedImage className="items-build-img m-1 w-16" src={imagePaths[4]} /></td>
						<td><SizedImage className="items-build-img m-1 w-16" src={imagePaths[5]} /></td>
						<td><SizedImage className="m-1 w-12" src={shardBuffImg} /></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
}